import "../../users/view-user/viewUser.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../../../../hooks/useAuth";
import axiosInstance from "../../../../api/axios";
import ApplicationForm from "../../../apply/applicationForm";

const ViewFromOps = () => {
  const { index } = useParams();
  const [email, setEmail] = useState("");
  const { authState } = useAuth();
  const [application, setApplication] = useState({});
  const [acceptOrDecline, setAD] = useState("");
  const [priorStatus, setPriorStatus] = useState("");
  const [travelReimbursement, setTravelReimbursement] = useState(false);
  const [housingAccommodations, setHousingAccommodations] = useState(false);
  const [oldTravelReimbursement, setOldTravelReimbursement] = useState(false);
  const [oldHousingAccommodations, setOldHousingAccommodations] =
    useState(false);
  const [teammates, setTeammates] = useState([]);
  const [noMore, setNoMore] = useState(false);
  const [status, setStatus] = useState("to_review");

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const userEmailResponse = await axiosInstance.get(
          `/api/applications/application-index/${authState.user.email}/${index}`
        );

        if (userEmailResponse.data.email === "dne") {
          setNoMore(true);
        }

        setEmail(userEmailResponse.data.email);
        setStatus(userEmailResponse.data.status);

        const response = await axiosInstance.get(
          `/api/applications/status/${userEmailResponse.data.email}`,
          userEmailResponse.data.email
        );
        setAD(response.data["status"]);
        setPriorStatus(response.data["status"]);
        setTravelReimbursement(response.data["travel_reimbursement"]);
        setOldTravelReimbursement(response.data["travel_reimbursement"]);
        setHousingAccommodations(response.data["housing_accommodations"]);
        setOldHousingAccommodations(response.data["housing_accommodations"]);

        const applicationResponse = await axiosInstance.get(
          `/api/applications/${userEmailResponse.data.email}`
        );

        if (applicationResponse.data.hasOwnProperty("teamcode")) {
          const teammates_response = await axiosInstance.get(
            `/api/applications/teammates/${applicationResponse.data.teamcode}`
          );
          setTeammates(teammates_response.data.emails);
        }

        setApplication(applicationResponse.data);
      } catch (err) {}
    };

    fetchStatus();
  }, []);

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.get(
        `/api/resume/download/${email}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${email}_resume.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {}
  };

  const handleSelectChange = (event) => {
    setAD(event.target.value);
  };

  const handleStatusChange = async (e) => {
    setStatus(e.target.value);

    try {
      const response = await axiosInstance.put(
        `/api/applications/change-review-status/${authState.user.email}/${email}/${e.target.value}`
      );

      setStatus(e.target.value);
    } catch (err) {}
  };

  const handleSubmitStatus = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post(
        `/api/applications/status/${email}`,
        {
          status: acceptOrDecline,
          travel_reimbursement: travelReimbursement,
          housing_accommodations: housingAccommodations,
        }
      );
      window.location.reload(true);
    } catch (err) {}
  };

  return (
    <div>
      {noMore && <h1 className="no-more-h1">No More to Review</h1>}

      <div className="dashboard">
        <div className="title-div">
          <div>
            <h1 className="user-dash-title">
              User: {application.first} {application.last}
            </h1>
            <p>Email: {email}</p>
            <p>Current Status: {priorStatus}</p>
            <p>Travel Reimbursement: {oldTravelReimbursement ? "Yes" : "No"}</p>
            <p>
              Housing Accommodations: {oldHousingAccommodations ? "Yes" : "No"}
            </p>
            <div className="vu-actions">
              <button className="user-dash-b">
                <a href="/admin/reviewapps">Return to Users</a>
              </button>
              <button className="user-dash-b">
                <a href={`/admin/reviewapps/${Number(index) - 1}`}>Previous</a>
              </button>
              <button className="user-dash-b">
                <a href={`/admin/reviewapps/${Number(index) + 1}`}>Next</a>
              </button>
              <select
                className="user-dash-b"
                name="ad"
                value={status}
                onChange={handleStatusChange}
              >
                <option value="to_review">To Review</option>
                <option value="complete">Complete</option>
              </select>
            </div>
          </div>
        </div>

        <div className="title-div">
          <div className="left-div">
            <h1 className="user-dash-title">Tools</h1>
          </div>
          <div className="right-div">
            <div>
              <form onSubmit={handleSubmitStatus} className="vu-form">
                <div className="vu-labelselect">
                  <label className="label-vu">Accept / Reject Hacker</label>
                  <select
                    className="confirm-deadline"
                    name="ad"
                    value={acceptOrDecline}
                    onChange={handleSelectChange}
                  >
                    <option value="submitted">No Decision</option>
                    <option value="accepted">Accepted</option>
                    <option value="waitlisted">Waitlisted</option>
                    <option value="rejected">Rejected</option>
                    <option value="waitlist_accepted">Waitlist Accepted</option>
                  </select>
                </div>
                <div className="vu-labelselect">
                  <label className="label-vu">Travel Reimbursement</label>
                  <select
                    className="confirm-deadline"
                    value={travelReimbursement}
                    onChange={(e) =>
                      setTravelReimbursement(e.target.value === "true")
                    }
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
                <div className="vu-labelselect">
                  <label className="label-vu">Housing Accommodations</label>
                  <select
                    className="confirm-deadline"
                    value={housingAccommodations}
                    onChange={(e) =>
                      setHousingAccommodations(e.target.value === "true")
                    }
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
                <button type="submit" className="user-dash-b">
                  Save Decision
                </button>
              </form>
            </div>
            <div>
              <label className="label-vu">Download User's Resume</label>
              <button
                onClick={handleDownload}
                className="button-vu"
                type="submit"
              >
                Download Resume
              </button>
            </div>
          </div>
        </div>

        <div className="title-div">
          <div className="left-div">
            <h1 className="user-dash-title">Team Information</h1>
          </div>
          <div className="right-div">
            <div>
              <h2 className="ad-vu-scores-title">
                Team Code: {application.teamcode}
              </h2>
            </div>
            <div>
              <h2 className="ad-vu-scores-title">Team</h2>
              <ul className="ad-vu-list">
                {teammates.map((email, index) => (
                  <li key={index} className="ad-vu-score">
                    <a className="ad-vu-score-a" href={`/admin/users/${email}`}>
                      {email}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="title-div">
          <div className="left-div">
            <h1 className="user-dash-title">Score User</h1>
          </div>
          <div className="right-div">
            <div>
              <form className="ad-vu-form">
                <input
                  type="numeric"
                  className="ad-vu-enterscore"
                  placeholder="Enter Score"
                />
                <button type="submit" className="user-dash-b">
                  Submit Score
                </button>
              </form>
            </div>
          </div>
          <div>
            <h2 className="ad-vu-scores-title">Current Scores</h2>
            <ul className="ad-vu-list">
              <li className="ad-vu-score">Mark: 6</li>
              <li className="ad-vu-score">Mark: 6</li>
              <li className="ad-vu-score">Mark: 6</li>
            </ul>
          </div>
          <div className="ad-vu-averagescore">
            <h2 className="ad-vu-scores-title">Average Score</h2>
            <p className="ad-vu-average-score-p">6.0</p>
          </div>
        </div> */}

        {/* <div className="title-div">
          <div className="left-div">
            <h1 className="user-dash-title">Confirmation Details</h1>
          </div>
          <div className="right-div">
            <div>
              <h2 className="contact-info-h1">Contact</h2>
              <p className="email-p">Email: {email}</p>
            </div>
          </div>
        </div> */}

        <div className="bottom-div">
          <h1 className="user-dash-title-bd">Application Details</h1>
          <ApplicationForm email={email} />
        </div>
      </div>
    </div>
  );
};

export default ViewFromOps;
