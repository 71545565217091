import axiosInstance from "../../../api/axios";
import "./config.css";
import { useEffect, useState } from "react";

const Config = () => {
  const [dates, setDates] = useState({
    start: "",
    end: "",
    housing: "",
    confirmation_first_deadline: "",
    confirmation_second_deadline: "",
    due: "",
    waitlist_deadline: "",
  });
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [cycle, setCycle] = useState("");
  const [year, setYear] = useState("");
  const [housingLink, setHousingLink] = useState("");

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/applications/cycle/config"
        );

        // console.log(response.data);

        if (response.data) {
          setDates(
            response.data.dates || {
              start: "",
              end: "",
              housing: "",
              confirmation_first_deadline: "",
              confirmation_second_deadline: "",
              due: "",
              waitlist_deadline: "",
            }
          );
          setStatus(response.data.status || "");
          setLocation(response.data.location || "");
          setHousingLink(response.data.housing_link || "");
        }
      } catch (error) {
        setMessage("Error fetching configuration");
      }

      try {
        const response = await axiosInstance.get(
          "/api/applications/cycle/current"
        );
        if (response.data) {
          setCycle(response.data.cycle || "");
          setYear(response.data.year || "");
        }
      } catch (error) {
        setMessage("Error fetching configuration");
      }
    };

    fetchConfig();
  }, []);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleCycleChange = (e) => {
    setCycle(e.target.value);
  };

  const handleYearChage = (e) => {
    setYear(e.target.value);
  };

  const handleHousingLink = (e) => {
    setHousingLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const r = await axiosInstance.post("/api/applications/cycle/current", {
        year,
        cycle,
      });
      console.log(r);
      setMessage("Configuration updated successfully");
    } catch (error) {
      console.log(error);
      setMessage("Error updating configuration");
    }

    try {
      await axiosInstance.post("/api/applications/cycle/config", {
        dates: dates,
        status: status,
        location: location,
        housing_link: housingLink,
      });
      setMessage("Configuration updated successfully");
    } catch (error) {
      setMessage("Error updating configuration");
    }
  };

  return (
    <div>
      <div className="dashboard">
        <h1 className="ad-dash-title">Configuration</h1>

        <form className="questions" onSubmit={handleSubmit}>
          {/* <div className="cofig-dash"> */}
          <p className="ev-status">Event Status (Required)</p>
          <select
            className="selector-c"
            id="dropdown"
            value={status}
            onChange={handleStatusChange}
            required
          >
            <option value="not_open">Not Open</option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
            <option value="released">Released</option>
            <option value="confirmation_closed">
              First Round Confirmations Closed
            </option>
            <option value="waitlist_closed">Waitlist Closed</option>
            <option value="second_confirmation_closed">
              Waitlist Admits Confirmation Closed
            </option>
          </select>

          <div className="cofig-dash">
            <p className="ev-status">Hackathon Cycle</p>
            <select
              className="selector-c"
              id="dropdown"
              value={cycle}
              onChange={handleCycleChange}
              required
            >
              <option value="fall">Fall</option>
              <option value="spring">Spring</option>
            </select>
          </div>

          <div className="hack-info-d">
            <label>Hackathon Year</label>
            <input
              className="hack-info-t"
              type="number"
              name="year"
              value={year}
              onChange={handleYearChage}
              required
            />

            <label>Hackathon Location</label>
            <input
              className="hack-info-t"
              type="text"
              name="location"
              value={location}
              onChange={handleLocationChange}
              required
            />

            <label>Application Due Date</label>
            <input
              className="hack-info-t"
              type="date"
              name="due"
              value={dates.due}
              onChange={handleDateChange}
              required
            />

            <label>Join Waitlist Deadline</label>
            <input
              className="hack-info-t"
              type="date"
              name="waitlist_deadline"
              value={dates.waitlist_deadline}
              onChange={handleDateChange}
              required
            />

            <label>Regular Acceptance Confirmation Deadline</label>
            <input
              className="hack-info-t"
              type="date"
              name="confirmation_first_deadline"
              value={dates.confirmation_first_deadline}
              onChange={handleDateChange}
              required
            />

            <label>Waitlist Acceptance Confirmation Deadline</label>
            <input
              className="hack-info-t"
              type="date"
              name="confirmation_second_deadline"
              value={dates.confirmation_second_deadline}
              onChange={handleDateChange}
              required
            />

            <label>Housing Accommodations Form Due Date</label>
            <input
              className="hack-info-t"
              type="date"
              name="housing"
              value={dates.housing}
              onChange={handleDateChange}
              required
            />

            <label>Housing Accommodations Form Link</label>
            <input
              className="hack-info-t"
              type="url"
              name="housing-link"
              value={housingLink}
              onChange={handleHousingLink}
              required
            />

            <label>Hackathon Start Date</label>
            <input
              className="hack-info-t"
              type="date"
              name="start"
              value={dates.start}
              onChange={handleDateChange}
              required
            />

            <label>Hackathon End Date</label>
            <input
              className="hack-info-t"
              type="date"
              name="end"
              value={dates.end}
              onChange={handleDateChange}
              required
            />

            <button className="button-c" type="submit">
              Submit
            </button>
            {message && (
              <span
                style={{
                  color: "var(--tertiary-color)",
                  "padding-top": "18px",
                  "margin-bottom": "5vh",
                }}
              >
                {message}
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Config;
