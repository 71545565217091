import "./whitelistemails.css";
import WhitelistTable from "./whitelisttable.js";
import { useState } from "react";
import axiosInstance from "../../../api/axios";

const WhitelistEmails = () => {
  const [whitelistPatterns, setWhitelistPatterns] = useState([]);
  const [emailByPass, setEmailByPass] = useState("");
  const [pattern, setPattern] = useState("");

  const fetchWhitelist = async () => {
    try {
      const response = await axiosInstance.get("/api/auth/whitelist");
      // console.log(response);
      setWhitelistPatterns(response.data);
    } catch (err) {}
  };

  const handleEmailChange = (e) => {
    setEmailByPass(e.target.value);
  };

  const handleEmailSubmission = async () => {
    // console.log("Submitting");
    try {
      const response = await axiosInstance.post(
        `/api/applications/whitelistemail/${emailByPass}`
      );
      // console.log(response);
      setEmailByPass("");
    } catch (err) {
      // console.log("Email failed");
    }
  };

  const handlePatternChange = (e) => {
    setPattern(e.target.value);
  };

  const handlePatternSubmit = async () => {
    // console.log("Submitting");

    try {
      const response = await axiosInstance.post(
        `/api/applications/whitelistpattern/${pattern}`
      );
      // console.log(response);
      setPattern("");
    } catch (err) {
      // console.log("Pattern failed");
    }
  };

  return (
    <div className="whitelistemails-background">
      <h1 className="ad-dash-title">Whitelist Emails</h1>

      <div className="searches-ad">
        <form onSubmit={handleEmailSubmission}>
          <h1 className="title-ad">Email Bypass</h1>

          <p>Allow specific email to bypass registration restrictions</p>
          <input
            className="find-selector-ad"
            name="email"
            type="text"
            placeholder="User Email"
            value={emailByPass}
            onChange={handleEmailChange}
          ></input>
          <button className="button-ad" type="submit">
            Whitelist Email
          </button>
        </form>

        <form onSubmit={handlePatternSubmit}>
          <h1 className="title-ad">Pattern Bypass</h1>

          <p>Allow pattern to bypass registration restrictions</p>
          <p>
            For a specific domain enter example.ex or for all endings enter *.ex
          </p>
          <input
            className="find-selector-ad"
            name="email"
            type="text"
            placeholder="Regular Expression"
            value={pattern}
            onChange={handlePatternChange}
          ></input>
          <button className="button-ad" type="submit">
            Whitelist Pattern
          </button>
        </form>
      </div>

      <div className="get-users-ad">
        <h1 className="title-ad">Current Exception List</h1>
        <WhitelistTable
          whitelistPatterns={whitelistPatterns}
          fetchList={fetchWhitelist}
        />
      </div>
    </div>
  );
};

export default WhitelistEmails;
