import "./adminDashboard.css";
import AdminCard from "./adminCard";

const AdminDashboard = () => {
  return (
    <div className="dashboard">
      <h1 className="ad-dash-title">Admin Panel</h1>

      <div className="admin-cards-div">
        <div className="admin-cards">
          <AdminCard
            link="/admin/users"
            title="Applications"
            description="Information about users who submitted an application."
          />
          <AdminCard
            link="/admin/stats"
            title="Statistics"
            description="View statistics relating to the applicant and attendee pools."
          />
          <AdminCard
            link="/admin/batch"
            title="Batch Decisions"
            description="Batch accept, waitlist or reject hackers."
          />
          <AdminCard
            link="/admin/administrators"
            title="Admin Users"
            description="Add and manage admin users."
          />
          <AdminCard
            link="/admin/config"
            title="Configuration"
            description="Manage the site configuration."
          />
          <AdminCard
            link="/admin/resumebook"
            title="Resume Book"
            description="Create a resume book of all our hackers!"
          />

          <AdminCard
            link="/admin/assignapps"
            title="Assign Applications"
            description="Assign applications to operations members for review."
          />

          <AdminCard
            link="/admin/reviewapps"
            title="Review Applications"
            description="Operations member portal for reviewing assigned applications."
          />

          <AdminCard
            link="/admin/whitelist-emails"
            title="Whitelist Emails"
            description="Allow email addresses to bypass registration restrictions."
          />

          {/* <AdminCard
            link="/admin/automated-emails"
            title="Automated Emails"
            description="Send a mass email to hackers. (In development)"
          />

          <AdminCard
            link="/admin/createapp"
            title="Create Application"
            description="Build this year's hacker application! (In development)"
          />

          <AdminCard
            link="/admin/demographics"
            title="Demographics"
            description="View demographics on this year's hackers. (In development)"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
